<template>
  <n-message-provider>
    <div class="content">
      <Nav />
      <router-view />
    </div>
  </n-message-provider>
</template>
<script>
import Nav from './components/Nav.vue'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import { useIsActivating } from './hooks/useIsActivating'
export default {
  components: {
    Nav
  },
  setup() {
    const store = useStore()
    const { getProvider } = useIsActivating()
    const provider = computed(() => {
      return store.state.provider
    })
    const network = computed(() => {
      return store.state.network
    })
    const fn = () => {
      let ethereum = window.ethereum
      ethereum.on("accountsChanged", (accounts) => {
        console.log("账号切换", accounts[0])
        if (!accounts[0]) {
          store.commit('init')
        } else {
          getProvider()
        }
      })
      ethereum.on("chainChanged", (chainId) => {
        console.log("用户切换了链", chainId)
        getProvider()
      })
    }
    
    onMounted(() => {
      getProvider(true)
      fn()
    })
    return {
      provider,
      network,
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.content {
  // background: #F7F9FC;
}

</style>
