import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Trigger from '../views/Trigger.vue'

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
}, {
  path: '/login',
  name: 'Login',
  component: () => import('../views/Login.vue')
}, {
  path: '/trigger/:id',
  name: 'Trigger',
  component: Trigger
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
