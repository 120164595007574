<template>
  <div class="model-w flex-center-center" v-if="isShowModel">
    <div class="model">
      <div class="model-hd flex-center-sb">
        <div class="model-title">新建策略</div>
        <div class="model-close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancel">
            <path d="M3.33325 3.33334L12.6666 12.6667M12.6666 3.33334L3.33325 12.6667" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="model-main">
        <div class="label-item">
          <div class="label">合约名称</div>
          <div class="input">
            <input type="text" v-model="contractData.name" placeholder="请输入合约名称">
          </div>
        </div>
        <div class="label-item">
          <div class="label">合约网络</div>
          <Select 
            placeholder="请选择网络"
            :value="contractData.chain"
            :options="chains"
            optionLabel="name"
            optionValue="chainId"
            @change="chainChange" />
        </div>
        <div class="label-item">
          <div class="label">合约地址</div>
          <div class="input">
            <input type="text" v-model="contractData.contractAddress" placeholder="请输入合约地址">
          </div>
        </div>
        <div class="label-item">
          <div class="label">合约ABI</div>
          <div class="input">
            <textarea placeholder="请输入合约ABI" v-model="contractData.abi"></textarea>
          </div>
        </div>
      </div>
      <div class="ft flex-center">
        <div class="ft-btn flex-center-center" @click="cancel">取消</div>
        <div class="ft-btn ft-btn-ok flex-center-center" @click="addContractFun">验证合约</div>
      </div>
    </div>
  </div>
  
</template>
<script>
import { computed, toRaw, ref } from 'vue'
import { useStore } from 'vuex'
import { addContract } from '../http/api'
import Select from '../components/Select.vue'
import { chains } from '../libs/chains'
import { useIsActivating } from '../hooks/useIsActivating'
import '@/assets/css/model.scss'
import { useMessage } from "naive-ui"
export default {
  components: {
    Select
  },
  setup(props, {emit}) {
    const message = useMessage()
    const store = useStore()
    const { getProvider } = useIsActivating()
    const isShowModel = ref(false)
    const contractData = ref({})
    const provider = computed(() => {
      return store.state.provider
    })
    const address = computed(() => {
      return store.state.address
    })
    const addContractFun = async () => {
      console.log(1)
      if (!address.value) {
        getProvider()
      } else if (!contractData.value.name) {
        message.error('请输入合约名称')
      } else if(!contractData.value.chain) {
        message.error('请选择合约网络')
      } else if(!contractData.value.contractAddress) {
        message.error('请输入合约地址')
      } else if(!contractData.value.abi) {
        message.error('请输入合约ABI')
      } else {
        let abi = contractData.value.abi
        abi = JSON.parse(contractData.value.abi)
        let msg = "Sign"
        const time = new Date().getTime()
        const sign_msg = `${msg}_${time}`
        let signature = await toRaw(provider.value).getSigner().signMessage(sign_msg)
        addContract({
          name: contractData.value.name,
          contractAddress: contractData.value.contractAddress,
          abi,
          user_address: address.value,
          message: sign_msg,
          signature,
          chain: contractData.value.chain
        }).then(res => {
          console.log(res)
          if (res.code == 0) {
            emit('add', res.contract_id)
            cancel()
          } else {
            message.error(res.msg)
          }
        })
      }
    }
    const chainChange = (e) => {
      contractData.value.chain = e
    }
    const cancel = () => {
      isShowModel.value = false
      contractData.value = {}
    }
    return {
      chains,
      contractData,
      isShowModel,
      addContractFun,
      chainChange,
      cancel
    }
  },
}
</script>
