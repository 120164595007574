<template>
  <div class="model-w flex-center-center" v-if="isShowModel">
    <div class="model">
      <div class="model-hd flex-center-sb">
        <div class="model-title">新建策略</div>
        <div class="model-close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancel">
            <path d="M3.33325 3.33334L12.6666 12.6667M12.6666 3.33334L3.33325 12.6667" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="model-main">
        <div class="label-item">
          <div class="label">策略名称</div>
          <div class="input">
            <input type="text" placeholder="请输入策略名称" v-model="triggerData.triggerName">
            <div class="input-num flex-center-center">0/20</div>
          </div>
        </div>
        <div class="label-item">
          <div class="label">策略网络</div>
          <Select 
            placeholder="请选择网络"
            :value="triggerData.chain"
            :options="chains"
            optionLabel="name" 
            optionValue="chainId" 
            @change="chainChange" />
        </div>
        <div class="label-item">
          <div class="label">选择触发器</div>
          <div class="trigger flex-center">
            <div :class="['trigger-item', triggerData.type == 'address' ? 'trigger-item-activated' : '']" @click="() => triggerData.type = 'address'">地址触发器</div>
            <div :class="['trigger-item', triggerData.type == 'contract' ? 'trigger-item-activated' : '']" @click="() => triggerData.type = 'contract'">合约触发器</div>
          </div>
        </div>
        <div class="add-trigger label-item">
          <div v-if="triggerData.type == 'address'" class="address-triggrt">
            <div class="address-triggrt-hd flex-center-sb">
              <div class="address-l">地址</div>
              <div class="address-r">操作</div>
            </div>
            <div v-if="!triggerData?.addressList?.length" class="address-not flex-center-center">暂无地址</div>
            <div v-else class="address-list">
              <div v-for="(item, index) in triggerData.addressList" :key="index" class="address-item flex-center-sb">
                <p>{{item}}</p>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="delAddress(index)">
                  <path d="M9.3335 7.2666V10.5999" stroke="#B6BBC2" stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M6.6665 7.2666V10.5999" stroke="#B6BBC2" stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M12.0002 4.59998H4.00016L4 12.6C4 12.9682 4.29848 13.2666 4.66667 13.2666H11.3333C11.7015 13.2666 12 12.9682 12 12.6L12.0002 4.59998Z" stroke="#B6BBC2" stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M2.6665 4.59998H13.3332" stroke="#B6BBC2" stroke-linecap="square" stroke-linejoin="round"/>
                  <path d="M10.0002 2.59998H6.00016C5.63197 2.59998 5.3335 2.89845 5.3335 3.26664V4.59998H10.6668V3.26664C10.6668 2.89845 10.3684 2.59998 10.0002 2.59998Z" stroke="#B6BBC2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div class="address-btn flex-center-center" @click="addAddress">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.0001 2.00006V14.0002M14.0001 7.99992H2" stroke="#262C33" stroke-width="1.2" stroke-linejoin="round"/>
              </svg>
              添加当前地址
            </div>
          </div>
          <div v-else class="contract-triggrt">
            <div class="label-item">
              <div class="label">选择合约</div>
              <Select 
                v-if="contractList.length"
                placeholder="请选择合约"
                :value="triggerData.contractId"
                :options="contractList"
                optionLabel="name"
                optionValue="id"
                :isAddContract="true"
                @change="contractChange"
                @addContract="addContract" />
              <div v-else class="flex-center-center add-contract-btn" @click="addContract">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.0001 2V14.0001M14.0001 7.99986H2" stroke="#262C33" stroke-width="1.2" stroke-linejoin="round"/>
                </svg>
                <span style="margin-left: 8px">添加合约</span>
              </div>
            </div>
            <div class="label-item">
          <div class="label">触发函数</div>
          <Select 
            placeholder="请选择触发函数"
            :value="triggerData.functionName"
            :options="abi"
            optionLabel="name"
            optionValue="name"
            @change="abiChange" />
        </div>
        <!-- <div class="label-item">
          <div class="label">备注名称</div>
          <div class="input">
            <input type="text" placeholder="请输入策略名称">
            <div class="input-num flex-center-center">0/20</div>
          </div>
        </div> -->
          </div>
        </div>
      </div>
      <div class="ft flex-center">
        <div class="ft-btn flex-center-center" @click="cancel">取消</div>
        <div v-if="!triggerData.id" class="ft-btn ft-btn-ok flex-center-center" @click="addTriggerFun">新建策略</div>
      </div>
    </div>
    <AddContractModel ref="addContractModel" @add="add" />
  </div>
  
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useIsActivating } from '../hooks/useIsActivating'
import { getContractList, addTrigger } from '../http/api'
import AddContractModel from '../components/AddContractModel.vue'
import Select from '../components/Select.vue'
import { chains } from '../libs/chains'
import '@/assets/css/model.scss'
import { useMessage } from "naive-ui"

export default {
  components: {
    AddContractModel,
    Select
  },
  setup(props, {emit}) {
    const message = useMessage()
    const store = useStore()
    const { getProvider } = useIsActivating()
    const contractList = ref([])
    const abi = ref([])
    const triggerData = ref({type: 'contract', addressList: []})
    const isShowModel = ref(false)
    const addContractModel = ref(null)
    const address = computed(() => {
      return store.state.address
    })
    const provider = computed(() => {
      return store.state.provider
    })
    const cancel = () => {
      isShowModel.value = false
      triggerData.value = {}
      emit('cancel')
    }
    const addTriggerFun = () => {
      const { contractId, functionName, triggerName, chain, type, addressList } = triggerData.value
      if (type == 'contract') {
        addTrigger({trigger_type: 'contract', contract_id: contractId, function_name: functionName, trigger_name: triggerName, chain}).then(res => {
          console.log(res)
          if (res.code == 0) {
            cancel()
            message.success('创建成功')
          } else {
            message.error(res.msg)
          }
          
        })
      } else if (type == 'address') {
        addTrigger({trigger_type: 'address', address_list: addressList, trigger_name: triggerName, chain}).then(res => {
          if (res.code == 0) {
            cancel()
            message.success('创建成功')
          } else {
            message.error(res.msg)
          }
        })
      }
      emit('add')
    }
    const chainChange = (e) => {
      console.log(e)
      triggerData.value.chain = e
    }
    const contractChange = (e) => {
      console.log(e)
      triggerData.value.contractId = e
      contractList.value.forEach(el => {
        if (el.id == e) {
          console.log(el.abi, JSON.parse(el.abi))
          abi.value = JSON.parse(el.abi)
          console.log(abi.value)
        }
      })
    }
    const abiChange = (e) => {
      console.log(e)
      triggerData.value.functionName = e
    }
    const addContract = () => {
      console.log(1)
      addContractModel.value.isShowModel = true
    }
    const addAddress = () => {
      if (!provider.value) {
        getProvider()
      } else {
        if (triggerData.value.addressList.indexOf(address.value) == -1) {
          triggerData.value.addressList.push(address.value)
        }
      }
    }
    const delAddress = (index) => {
      triggerData.value.addressList.splice(index, 1)
    }
    const getContractListFun = () => {
      getContractList().then(res => {
        console.log(res)
        if (res.code == 0) {
          contractList.value = res.contract_list
        } else {
          message.error(res.msg)
        }
      })
    }
    const add = (id) => {
      getContractListFun()
      triggerData.value.contractId = id
    }
    onMounted(() => {
      getContractListFun()
    })
    return {
      triggerData,
      contractList,
      chains,
      abi,
      isShowModel,
      addContractModel,
      addTriggerFun,
      chainChange,
      contractChange,
      abiChange,
      addContract,
      cancel,
      addAddress,
      delAddress,
      add
    }
  },
}
</script>
<style lang="scss" scoped>
.add-contract-btn {
  height: 40px;
  font-size: 13px;
  color: #262C33;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  margin-top: 12px;
  cursor: pointer;
}
.address-btn {
  cursor: pointer;
}
</style>