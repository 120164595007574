<template>
  <div class="home">
    <div class="content flex-start">
      <div class="left">
        <div class="menu-item flex-center">GasLess</div>
      </div>
      <div class="right">
        <div class="title">策略</div>
        <div class="trigger-list flex-center">
          <div class="trigger-item trigger-item-img" @click="showAddTriggerModel">
            <img src="@/assets/images/add.svg" alt="">
          </div>
          <div v-for="item in triggerList" :key="item.id" class="trigger-item trigger-item-data" @click="toDetail(item.id)">
            <svg width="301" height="129" viewBox="0 0 301 129" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="301" height="129" fill="#F7F9FC"/>
            </svg>
            <div class="item-info">
              <p class="item-name">{{item.trigger_name}}</p>
              <p class="item-time">{{createAt(item.update_time * 1000)}} 更新</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddTriggerModel ref="addTriggerModel" @add="add" />
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import AddTriggerModel from '../components/AddTriggerModel.vue'
import { getTriggerList } from '../http/api'
import { useRouter } from 'vue-router'
import { formatDate } from '../libs/utils'
import { useMessage } from "naive-ui"
export default {
  name: 'Home',
  components: {
    AddTriggerModel
  },
  setup() {
    const message = useMessage()
    const router = useRouter()
    const triggerList = ref([])
    const addTriggerModel = ref(null)
    const createAt = computed(() => {
      return (value) => {
        if (!value) return '--'
        return formatDate('YYYY-mm-dd HH:MM:SS', value)
      }
    })
    const showAddTriggerModel = () => {
      addTriggerModel.value.isShowModel = true
    }
    const getTriggerListFun = () => {
      getTriggerList().then(res => {
        console.log(res)
        if (res.code == 0) {
          triggerList.value = res.trigger_list
        } else {
          message.error(res.msg)
        }
      })
    }
    const toDetail = (id) => {
      router.push(`trigger/${id}`)
    }
    const add = () => {
      getTriggerListFun()
    }
    onMounted(() => {
      getTriggerListFun()
    })
    return {
      addTriggerModel,
      triggerList,
      showAddTriggerModel,
      add,
      toDetail,
      createAt
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  height: calc(100vh - 60px);
  box-sizing: border-box;
  .left {
    height: 100%;
    width: 100px;
    border-right: 1px solid #DAE2EC;
    background: #FFFFFF;
    padding-bottom: 23px;
    box-sizing: border-box;
    .menu-item {
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      height: 30px;
      background: rgba(201, 209, 220, 0.3);
      padding-left: 13px;
      box-sizing: border-box;
    }
  }
  .right {
    padding: 32px;
    padding-right: 0;
    box-sizing: border-box;
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #262C33;
    }
    .trigger-list {
      margin-top: 24px;
      flex-wrap: wrap;
      .trigger-item {
        width: 301px;
        height: 199px;
        background: #FFFFFF;
        border-radius: 6px;
        margin-right: 32px;
        margin-bottom: 32px;
        cursor: pointer;
        &.trigger-item-img {
          &:hover {
            background: #F7F9FC;
          }
        }
        &.trigger-item-data {
          border: 1px solid #EEEFF0;
          border-radius: 6px;
        }
        img {
          width: 301px;
          height: 199px;
          cursor: pointer;
        }
        svg {
          width: 100%;
          height: auto;
        }
        .item-info {
          padding: 16px;
          box-sizing: border-box;
          .item-name {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #262C33;
          }
          .item-time {
            margin-top: 8px;
            font-size: 12px;
            line-height: 14px;
            color: #B6BBC2;
          }
        }
      }
    }
  }
}
</style>
