<template>
  <div class="flex-start-center content">
    <div class="back-w flex-center">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="back">
        <path d="M10.6667 14L4.66675 8L10.6667 2" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
      </svg>
      <div class="trigger-title">{{triggerDetail.trigger_name}}</div>
      <div v-if="triggerDetail.status != 'published'" class="publish-btn flex-center-center" @click="publish">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1253_2386)">
            <path d="M1.29731 6.20995C0.949307 6.09395 0.945973 5.90661 1.30397 5.78728L14.0286 1.54595C14.3813 1.42861 14.5833 1.62595 14.4846 1.97128L10.8486 14.6953C10.7486 15.0479 10.5453 15.0599 10.396 14.7253L7.99997 9.33328L12 3.99995L6.66664 7.99995L1.29731 6.20995Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_1253_2386">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <span>Publish</span>
      </div>
    </div>
    <div v-if="!isShowModel" class="box">
      <div class="box-hd">Nodes</div>
      <div class="box-tab flex-center">
        <div :class="['box-tab-item', tabName == 'fun' ? 'box-tab-item-activated' : '', 'flex-center-center']" @click="() => tabName = 'fun'">判断</div>
        <div :class="['box-tab-item', tabName == 'result' ? 'box-tab-item-activated' : '', 'flex-center-center']" @click="() => tabName = 'result'">结果</div>
      </div>
      <div v-if="tabName == 'fun'" class="box-list">
        <div v-for="item in functionList" :key="item.fun_name" class="box-item flex-center" 
          draggable="true"
          @drag="handleDrag"
          @dragstart="handleDragstart(item.fun_name)"
          @dragend="handleDragend">
          <div class="icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="6" fill="#EFFAF5"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5625 11.5625C10.6305 11.5625 9.875 12.318 9.875 13.25V26.75C9.875 27.682 10.6305 28.4375 11.5625 28.4375H28.4375C29.3695 28.4375 30.125 27.682 30.125 26.75V13.25C30.125 12.318 29.3695 11.5625 28.4375 11.5625H11.5625ZM12 17.2002V22.9366H13.2374V19.2571L16.0155 22.9366H17.1955V17.2002H15.9663V20.8879L13.1801 17.2002H12ZM18.4582 17.2002V22.9366H19.753V20.765H22.3426V19.6832H19.753V18.2737H22.5721L22.5803 17.2002H18.4582ZM23.206 18.2983V17.2002H28V18.2983H26.2463V22.9366H24.9515V18.2983H23.206Z" fill="#31C48D"/>
            </svg>
          </div>
          <div class="info">
            <p class="title">{{item.name}}</p>
            <p class="desc">{{item.name}}</p>
          </div>
        </div>
      </div>
      <div v-if="tabName == 'result'" class="box-list">
        <div class="box-item flex-center" 
          draggable="true"
          @drag="handleDrag"
          @dragstart="handleDragstart('sponsor')"
          @dragend="handleDragend">
          <div class="icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="6" fill="#F0F7FF"/>
              <path d="M11.75 26.4167V12.6667C11.75 12.4236 11.8466 12.1904 12.0185 12.0185C12.1904 11.8466 12.4236 11.75 12.6667 11.75H20.9167C21.1598 11.75 21.393 11.8466 21.5649 12.0185C21.7368 12.1904 21.8334 12.4236 21.8334 12.6667V20H23.6667C24.1529 20 24.6193 20.1932 24.9631 20.537C25.3069 20.8808 25.5 21.3471 25.5 21.8333V25.5C25.5 25.7431 25.5966 25.9763 25.7685 26.1482C25.9404 26.3201 26.1736 26.4167 26.4167 26.4167C26.6598 26.4167 26.893 26.3201 27.0649 26.1482C27.2368 25.9763 27.3334 25.7431 27.3334 25.5V19.0833H25.5C25.2569 19.0833 25.0238 18.9868 24.8519 18.8148C24.68 18.6429 24.5834 18.4098 24.5834 18.1667V14.8795L23.0645 13.3606L24.3606 12.0644L28.8981 16.6019C28.9834 16.6869 29.051 16.7879 29.0971 16.8992C29.1432 17.0104 29.1668 17.1296 29.1667 17.25V25.5C29.1667 26.2293 28.877 26.9288 28.3613 27.4445C27.8455 27.9603 27.1461 28.25 26.4167 28.25C25.6874 28.25 24.9879 27.9603 24.4722 27.4445C23.9564 26.9288 23.6667 26.2293 23.6667 25.5V21.8333H21.8334V26.4167H22.75V28.25H10.8334V26.4167H11.75ZM13.5834 13.5833V19.0833H20V13.5833H13.5834Z" fill="#2152EC"/>
            </svg>
          </div>
          <div class="info">
            <p class="title">补贴交易</p>
            <p class="desc">设置 Gas 来源、补贴策略等</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box-w">
      <AddResultModel v-if="isShowResult" ref="addResultModel" :triggerId="triggerDetail.id" :chainId="triggerDetail.chain" @saveResult="saveResult" @cancel="cancel" />
      <AddFilterModel v-if="isShowFilter" ref="addFilterModel" @cancel="filterCancel" @save="saveFilter" />
      <AddTriggerModel v-if="isShowTrigger" ref="addTriggerModel" @cancel="triggerCancel" @save="saveTrigger" />
    </div>
    <div class="main flex-center-center">
      <div class="info flex-center-center">
        <div class="item" @click="showTriggerModal">
          <div class="item-hd flex-center">
            <img src="@/assets/images/addressIcon.svg" alt="">
            <div class="item-info">
              <p class="item-title">{{triggerDetail.trigger_name}}</p>
              <p class="item-desc">执行{{triggerDetail.function_name}}时将会自动触发后续动作</p>
            </div>
          </div>
          <!-- <div class="item-bd" v-if="triggerDetail.address">
            <p>0x64E193C40288F4ec1A24C5c92314d9518a6E8e49</p>
          </div> -->
        </div>
        <div v-if="filterList && filterList.length" class="filter-list flex-center-center column">
          <div v-for="(item, index) in filterList" :key="item.fun_name" @click="showFunModal(index)">
            <div class="connect flex-center-center">
              <img src="@/assets/images/connectIcon.svg" alt="">
            </div>
            <div class="item">
              <div class="item-hd flex-center">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="6" fill="#EFFAF5"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5625 11.5625C10.6305 11.5625 9.875 12.318 9.875 13.25V26.75C9.875 27.682 10.6305 28.4375 11.5625 28.4375H28.4375C29.3695 28.4375 30.125 27.682 30.125 26.75V13.25C30.125 12.318 29.3695 11.5625 28.4375 11.5625H11.5625ZM12 17.2002V22.9366H13.2374V19.2571L16.0155 22.9366H17.1955V17.2002H15.9663V20.8879L13.1801 17.2002H12ZM18.4582 17.2002V22.9366H19.753V20.765H22.3426V19.6832H19.753V18.2737H22.5721L22.5803 17.2002H18.4582ZM23.206 18.2983V17.2002H28V18.2983H26.2463V22.9366H24.9515V18.2983H23.206Z" fill="#31C48D"/>
                </svg>
                <div class="item-info">
                  <p class="item-title">{{item.filter_info && item.filter_info.name || 'filterName'}}</p>
                  <p class="item-desc">{{filterDesc(item.attributes)}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="triggerDetail.result_info && triggerDetail.result_info.id" class="filter-list flex-center-center column" @click="showResultModal">
          <div>
            <div class="connect flex-center-center">
              <img src="@/assets/images/connectIcon.svg" alt="">
            </div>
            <div class="item">
              <div class="item-hd flex-center">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="6" fill="#F0F7FF"/>
                  <path d="M11.75 26.4167V12.6667C11.75 12.4236 11.8466 12.1904 12.0185 12.0185C12.1904 11.8466 12.4236 11.75 12.6667 11.75H20.9167C21.1598 11.75 21.393 11.8466 21.5649 12.0185C21.7368 12.1904 21.8334 12.4236 21.8334 12.6667V20H23.6667C24.1529 20 24.6193 20.1932 24.9631 20.537C25.3069 20.8808 25.5 21.3471 25.5 21.8333V25.5C25.5 25.7431 25.5966 25.9763 25.7685 26.1482C25.9404 26.3201 26.1736 26.4167 26.4167 26.4167C26.6598 26.4167 26.893 26.3201 27.0649 26.1482C27.2368 25.9763 27.3334 25.7431 27.3334 25.5V19.0833H25.5C25.2569 19.0833 25.0238 18.9868 24.8519 18.8148C24.68 18.6429 24.5834 18.4098 24.5834 18.1667V14.8795L23.0645 13.3606L24.3606 12.0644L28.8981 16.6019C28.9834 16.6869 29.051 16.7879 29.0971 16.8992C29.1432 17.0104 29.1668 17.1296 29.1667 17.25V25.5C29.1667 26.2293 28.877 26.9288 28.3613 27.4445C27.8455 27.9603 27.1461 28.25 26.4167 28.25C25.6874 28.25 24.9879 27.9603 24.4722 27.4445C23.9564 26.9288 23.6667 26.2293 23.6667 25.5V21.8333H21.8334V26.4167H22.75V28.25H10.8334V26.4167H11.75ZM13.5834 13.5833V19.0833H20V13.5833H13.5834Z" fill="#2152EC"/>
                </svg>
                <div class="item-info">
                  <p class="item-title">补贴交易</p>
                  <p class="item-desc">补贴交易</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="!(triggerDetail.result_info && triggerDetail.result_info.id)" class="connect">
          <img src="@/assets/images/connectIcon.svg" alt="">
        </div>
        <div 
          v-if="!(triggerDetail.result_info && triggerDetail.result_info.id)"
          class="dragenter-w"
          @dragenter="handleDragenter"
          @dragover="handleDragover"
          @dragleave="handleDragleave"
          @drop="handleDrop"
        ></div>
      </div>
      <div v-if="(!isShowAddIcon && !filterList.length)" class="flex-center-center" style="flex-direction: column;">
        <div class="filters-w">
          <div class="filter-icon">
            <img src="@/assets/images/filterIcon.svg" alt="">
          </div>
        </div>
        <div class="connect mt18">
          <img src="@/assets/images/connectIcon.svg" alt="">
        </div>
        <div class="result-w">
          <div class="result-icon">
            <img src="@/assets/images/resultIcon.svg" alt="">
          </div>
        </div>
      </div>
      <div v-if="isShowAddIcon" class="add-item">
        <img src="@/assets/images/addIcon.svg" alt="">
        <img src="@/assets/images/addIcon.svg" alt="" class="add-bg">
        <img src="@/assets/images/addIcon.svg" alt="" class="add-bg">
      </div>
    </div>
    
  </div>
</template>
<script>
import { onBeforeMount, ref, watch, computed, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { getTriggerDetail, getFunctionList, publishTrigger, getContractList } from '../http/api'
import AddFilterModel from '../components/AddFilterModel.vue'
import AddResultModel from '../components/AddResultModel.vue'
import AddTriggerModel from '../components/AddTriggerModel.vue'
import { useMessage } from "naive-ui"
export default {
  components: {
    AddFilterModel,
    AddResultModel,
    AddTriggerModel
  },
  setup() {
    let dragFunName = ''
    const message = useMessage()
    const router = useRouter()
    const triggerId = ref('')
    const contractList = ref([])
    const addFilterModel = ref(null)
    const addTriggerModel = ref(null)
    const addResultModel = ref(null)
    const isShowAddIcon = ref(false)
    const isShowModel = ref(false)
    const isShowResult = ref(false)
    const isShowTrigger = ref(false)
    const isShowFilter = ref(false)
    const tabName = ref('fun')
    const triggerDetail = ref({})
    const functionList = ref([])
    const filterList = ref([])

    const filterDesc = computed(() => {
      return (value) => {
        if (!value) return '--'
        let str = ''
        for (let key in value) {
          if (key == '$gt') {
            str += `大于${value[key]}`
          } else if (key == '$lt') {
            str += `小于${value[key]}`
          } else if (key == '$gte') {
            str += `大于等于${value[key]}`
          } else if (key == '$lte') {
            str += `小于等于${value[key]}`
          } else if (key == '$eq') {
            str += `等于${value[key]}`
          } else if (key == '$ne') {
            str += `不等于${value[key]}`
          }
        }
        return str
      }
    })

    const handleDrag = () => {
      console.log('🚀🚀 ~ drag  元素被拖动')
    }

    const handleDragstart = (name) => {
      console.log('🚀🚀 ~ dragstart  元素开始被拖动')
      dragFunName = name
    }

    const handleDragend = () => {
      console.log('🚀🚀 ~ dragend  拖动操作完成')
      dragFunName = ''
    }

    const handleDragenter = () => {
      console.log('🚀🚀 ~ dragenter  拖动元素进入到释放区')
      isShowAddIcon.value = true
    }

    const handleDragover = (ev) => {
      ev.preventDefault()
      console.log('🚀🚀 ~ dragover  被拖动元素在释放区内移动')
    }

    const handleDragleave = () => {
      console.log('🚀🚀 ~ dragleave  被拖动元素没有放下就离开释放区')
      isShowAddIcon.value = false
    }

    const handleDrop = () => {
      console.log('🚀🚀 ~ drop  被拖动元素在释放区里放下')
      if (tabName.value == 'fun') {
        filterList.value.push(functionList.value.filter(e => e.fun_name == dragFunName)[0])
        isShowModel.value = true
        isShowFilter.value = true
        isShowResult.value = false
        const DF = dragFunName
        setTimeout(() => {
          console.log(addFilterModel.value)
          addFilterModel.value.functionName = DF
          addFilterModel.value.triggerId = triggerId.value
        }, 10)
      } else if (tabName.value == 'result') {
        isShowResult.value = true
        isShowModel.value = true
        isShowFilter.value = false
      }
    }

    const showTriggerModal = () => {
      isShowModel.value = true
      isShowTrigger.value = true
      isShowFilter.value = false
      isShowResult.value = false
      setTimeout(() => {
        addTriggerModel.value.isShowModel = true
        addTriggerModel.value.contractList = contractList.value
        let abi = []
        contractList.value.forEach(el => {
          if (el.id == triggerDetail.value.contract_id) {
            abi = JSON.parse(el.abi)
          }
        })
        addTriggerModel.value.abi = abi
        addTriggerModel.value.triggerData = {
          id: triggerDetail.value.id,
          triggerName: triggerDetail.value.trigger_name,
          type: triggerDetail.value.trigger_type,
          functionName: triggerDetail.value.function_name,
          contractId: triggerDetail.value.contract_id,
          chain: triggerDetail.value.chain
        }
        console.log(addTriggerModel.value)
      }, 10)
    }

    const showResultModal = () => {
      isShowResult.value = true
      isShowModel.value = true
      isShowFilter.value = false
      let result = triggerDetail.value.result_info
      setTimeout(() => {
        addResultModel.value.address = result.address
        addResultModel.value.resultId = result.id
      }, 10)
    }

    const showFunModal = (index) => {
      isShowModel.value = true
      isShowFilter.value = true
      isShowResult.value = false
      let functionName = toRaw(filterList.value)[index].function_name
      console.log(addFilterModel.value)
      setTimeout(() => {
        addFilterModel.value.functionName = functionName
        addFilterModel.value.triggerId = triggerId.value
        addFilterModel.value.filterId = toRaw(filterList.value)[index].id
        let list = filterList.value[index].attributes
        let filterListArr = []
        for (let key in list) {
          filterListArr.push({
            funName: key,
            value: list[key]
          })
        }
        addFilterModel.value.filterList = filterListArr
      }, 10)
    }

    const saveFilter = () => {
      isShowAddIcon.value = false
      addFilterModel.value.filterList = [{}]
      isShowModel.value = false
      getTriggerDetailFun()
    }

    const saveResult = () => {
      isShowAddIcon.value = false
      addFilterModel.value.filterList = [{}]
      isShowModel.value = false
      getTriggerDetailFun()
    }

    const triggerCancel = () => {
      isShowModel.value = false
      isShowResult.value = false
      isShowTrigger.value = false
      addTriggerModel.value.isShowModel = false
    }

    const filterCancel = () => {
      let functionName = addFilterModel.value.functionName
      for (let i = 0; i < filterList.value.length; i++) {
        let filterItem = filterList.value[i]
        if (filterItem.fun_name == functionName) {
          filterList.value.splice(i, 1)
        }
      }
      isShowModel.value = false
      isShowAddIcon.value = false
      isShowResult.value = false
    }

    const getTriggerDetailFun = () => {
      getTriggerDetail({trigger_id: triggerId.value}).then(res => {
        console.log(res)
        if (res.code == 0) {
          triggerDetail.value = res.trigger
          filterList.value = res.trigger.filter_list
        } else {
          message.error(res.msg)
        }
      })
    }

    const getContractListFun = () => {
      getContractList().then(res => {
        console.log(res)
        if (res.code == 0) {
          contractList.value = res.contract_list
        } else {
          message.error(res.msg)
        }
      })
    }

    onBeforeMount(() => {
      let id = router.currentRoute.value.params.id || ''
      if (id) {
        triggerId.value = id
        getTriggerDetailFun()
        getContractListFun()
      }
      getFunctionList().then(res => {
        console.log(res)
        if (res.code == 0) {
          functionList.value = res.fun_list
        } else {
          message.error(res.msg)
        }
      })
    })
    const formatFilterList = () => {
      filterList.value.forEach(e => {
        for (let i = 0; i< functionList.value.length; i++) {
          let funItem = functionList.value[i]
          if (e.function_name == funItem.fun_name) {
            functionList.value.splice(i, 1)
          }
        }
      })
      console.log(filterList.value)
    }
    const publish = () => {
      publishTrigger({trigger_id: triggerId.value}).then(res => {
        console.log(res)
        if (res.code == 0) {
          message.success('发布成功')
        } else {
          message.error(res.msg)
        }
      })
    }
    const cancel = () => {
      isShowAddIcon.value = false
      isShowModel.value = false
      isShowFilter.value = false
    }
    const back = () => {
      router.back()
    }
    watch(filterList, () => {
      if (functionList.value.length && functionList.value.length) {
        formatFilterList()
      }
    })
    watch(functionList, () => {
      if (functionList.value.length && functionList.value.length) {
        formatFilterList()
      }
    })
    return {
      triggerId,
      functionList,
      filterList,
      isShowAddIcon,
      isShowModel,
      addTriggerModel,
      addFilterModel,
      addResultModel,
      tabName,
      isShowResult,
      isShowFilter,
      isShowTrigger,
      triggerDetail,
      handleDrag,
      handleDragstart,
      handleDragend,
      handleDragenter,
      handleDragover,
      handleDragleave,
      handleDrop,
      saveFilter,
      saveResult,
      filterCancel,
      filterDesc,
      publish,
      back,
      cancel,
      showFunModal,
      showResultModal,
      showTriggerModal,
      triggerCancel
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  width: 100vw;
  min-height: calc(100vh - 60px);
  background: #F7F9FC;
  padding-bottom: 100px;
  box-sizing: border-box;
}
.back-w {
  position: absolute;
  height: 52px;
  left: 24px;
  top: 84px;
  background: #FFFFFF;
  box-shadow: 0px 6px 8px rgba(6, 30, 85, 0.03);
  padding: 0 10px 0 24px;
  box-sizing: border-box;
  border-radius: 10px;
  & > svg {
    cursor: pointer;
  }
  .trigger-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: 16px;
    margin-right: 16px;
    color: #262C33;
  }
  .publish-btn {
    width: 100px;
    height: 32px;
    background: #2152EC;
    border-radius: 6px;
    cursor: pointer;
    span {
      font-size: 13px;
      color: #FFFFFF;
      margin-left: 8px;
    }
  }
}
.box-w {
  position: fixed;
  right: 24px;
  top: 84px;
  width: 500px;
  box-shadow: 0px 16px 26px rgba(6, 30, 85, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  z-index: 999;
}
.box {
  width: 350px;
  background: #FFFFFF;
  border: 1px solid #EEEFF0;
  box-shadow: 0px 16px 26px rgba(6, 30, 85, 0.04);
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  position: fixed;
  right: 24px;
  top: 84px;
  .box-hd {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
  }
  .box-tab {
    margin-top: 16px;
    width: 100%;
    height: 40px;
    background: #F8F9FA;
    border-radius: 8px;
    padding: 4px;
    box-sizing: border-box;
    .box-tab-item {
      flex: 1;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #4C4F53;
      cursor: pointer;
      &.box-tab-item-activated {
        background: #FFFFFF;
        border: 1px solid #EEEFF0;
        border-radius: 6px;
        color: #262C33;
        height: 100%;
      }
    }
  }
  .box-list {
    margin-top: 24px;
    .box-item {
      padding: 8px;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      margin-bottom: 16px;
      background: #FFFFFF;
      cursor: pointer;
      &:hover {
        background: rgba(201, 209, 220, 0.3);
      }
      .info {
        margin-left: 12px;
        .title {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #262C33;
        }
        .desc {
          margin-top: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #B6BBC2;
        }
      }
    }
  }
}
.main {
  padding-top: 130px;
  box-sizing: border-box;
  flex-direction: column;
  .column {
    flex-direction: column;
  }
  .info {
    position: relative;
    flex-direction: column;
    .item {
      padding: 16px;
      box-sizing: border-box;
      width: 330px;
      background: #FFFFFF;
      border: 1px solid #EEEFF0;
      box-shadow: 0px 6px 8px rgba(6, 30, 85, 0.03);
      border-radius: 8px;
      .item-info {
        margin-left: 12px;
        .item-title {
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #262C33;
        }
        .item-desc {
          margin-top: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #B6BBC2;
        }
      }
      .item-bd {
        margin-top: 12px;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #262C33;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .dragenter-w {
      position: absolute;
      left: 0;
      right: 0;
      width: 150px;
      height: 80px;
      margin: auto;
      bottom: -75px;
      z-index: 9;
    }
  }
  
  .connect {
    margin-top: -4px;
    img {
      width: auto;
      height: 32px;
    }
  }
  .add-item {
    position: relative;
    img {
      width: 32px;
      height: auto;
      position: relative;
      z-index: 1;
    }
    .add-bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      animation: vabDot 1.2s linear infinite;
      z-index: 0;
      transform: scale(.8);
      &:last-child {
        animation-delay: .5s;
      }
    }
  }
  .filters-w {
    .filter-icon {
      margin-top: -6px;
      img {
        width: 151px;
        height: auto;
      }
    }
  }
  .result-w {
    .result-icon {
      margin-top: -6px;
      img {
        width: 130px;
        height: auto;
      }
    }
  }
  .mt18 {
    margin-top: -22px;
  }
}
@keyframes vabDot {
  0% {
    opacity: .3;
    transform: scale(.8)
  }

  to {
    opacity: 0;
    transform: scale(2.4)
  }
}
</style>