<template>
  <div class="model">
    <div class="model-hd flex-center-sb">
      <div class="model-title">判断余额</div>
      <div class="model-close">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancel">
          <path d="M3.33325 3.33334L12.6666 12.6667M12.6666 3.33334L3.33325 12.6667" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="model-main">
      <div class="label-item">
        <div class="label">判断方法</div>
        <div v-for="(item, index) in filterList" :key="index" class="filter">
          <Select 
            style="width: 100%"
            placeholder="请选择判断方法"
            :value="item.funName"
            :options="filterFunList"
            optionLabel="label"
            optionValue="value"
            @change="funChange($event, index)" />
          <div class="input" style="margin-left: 12px;">
            <input type="text" placeholder="请输入" v-model="item.value">
          </div>
          <svg v-if="index > 0" style="margin-left: 12px;cursor: pointer;" @click="delFun(index)" width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11" fill="#F98080"/>
            <path d="M7.91699 7.91675L16.0837 16.0834" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <path d="M16.083 7.91675L7.91634 16.0834" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
          <div v-else style="width: 48px;height: 48px;margin-left: 12px"></div>
        </div>
        <div class="address-btn flex-center-center" style="margin-top: 12px" @click="addFun">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.0001 2.00006V14.0002M14.0001 7.99992H2" stroke="#262C33" stroke-width="1.2" stroke-linejoin="round"/>
          </svg>
          添加规则
        </div>
      </div>
    </div>
    <div class="ft flex-center">
      <div class="ft-btn flex-center-center" @click="cancel">取消</div>
      <div v-if="!filterId" class="ft-btn ft-btn-ok flex-center-center" @click="addFilterFun">保存</div>
    </div>
  </div>
  
</template>
<script>
import { ref } from 'vue'
import { addFilter } from '../http/api'
import '@/assets/css/model.scss'
import Select from '../components/Select.vue'
import { useMessage } from "naive-ui"
export default {
  components: {
    Select
  },
  setup(props, {emit}) {
    const message = useMessage()
    const filterFunList = ref([{
      label: '大于',
      value: '$gt'
    }, {
      label: '小于',
      value: '$lt'
    }, {
      label: '大于等于',
      value: '$gte'
    }, {
      label: '小于等于',
      value: '$lte'
    }, {
      label: '等于',
      value: '$eq'
    }, {
      label: '不等于',
      value: '$ne'
    }])
    const filterList = ref([{}])
    const functionName = ref('')
    const triggerId = ref('')
    const filterId = ref('')
    const funChange = (e, index) => {
      console.log(e,index)
      filterList.value[index].funName = e
    }
    const addFun = () => {
      filterList.value.push({})
    }
    const delFun = (index) => {
      filterList.value.splice(index, 1)
    }
    const addFilterFun = async () => {
      console.log(filterList.value)
      if (!filterList.value.length) {
        message.error('请添加判断规则')
        return
      }
      let attributes = {}
      filterList.value.forEach(e => {
        attributes[e.funName] = e.value
      })
      addFilter({
        trigger_id: triggerId.value,
        function_name: functionName.value,
        attributes
      }).then(res => {
        console.log(res)
        if (res.code == 0) {
          emit('save')
        } else {
          // emit('cancel')
          message.error(res.msg)
        }
      })
    }
    const cancel = () => {
      emit('cancel')
    }
    return {
      filterId,
      triggerId,
      functionName,
      filterFunList,
      filterList,
      addFilterFun,
      funChange,
      addFun,
      delFun,
      cancel
    }
  },
}
</script>
<style lang="scss" scoped>
.filter {
  margin-top: 12px;
}
.address-btn {
  cursor: pointer;
}
</style>