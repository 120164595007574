<template>
  <div class="nav">
    <div class="nav-center flex-center">
      <div class="nav-l"><img src="@/assets/images/logo.svg" alt="" class="logo"></div>
      <div class="nav-r flex-center">
        <div v-if="address" class="wallet flex-center" @click="copy">
          <div class="balance">{{fixed(balance)}}</div>
          <div class="line"></div>
          <div class="address">{{formatAddress(address)}}</div>
          <div class="avatar flex-center">
            <Avatar 
              :size="14"
              :name="address" 
            />
          </div>
        </div>
        <div v-else class="wallet wallet-btn flex-center" @click="connectWallet">Connect Wallet</div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, watch, ref } from 'vue'
import { chains } from '../libs/chains'
import { useIsActivating } from '../hooks/useIsActivating'
import { useNetwork } from '../hooks/useNetwork'
import Avatar from "vue-boring-avatars"
export default {
  components: {
    Avatar,
  },
  setup() {
    const store = useStore()
    const { getProvider } = useIsActivating()
    const { switchChain } = useNetwork()
    const chainId = ref(null)
    const formatAddress = computed(() => {
      return (address) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
      }
    })
    const fixed = computed(() => {
      return (value) => {
        return value ? (+value).toFixed(2) : ''
      }
    })
    const network = computed(() => {
      return store.state.network
    })
    const address = computed(() => {
      return store.state.address
    })
    const balance = computed(() => {
      return store.state.balance
    })
    const handleUpdateValue = (e) => {
      switchChain(e)
    }
    const connectWallet = () => {
      getProvider()
    }
    const copy = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(address.value)
      }
    }
    watch(network, (val) => {
      chainId.value = val && val.chainId || null
    }, {immediate: true})
    return {
      chains,
      network,
      chainId,
      address,
      balance,
      formatAddress,
      fixed,
      handleUpdateValue,
      connectWallet,
      copy
    }
  }
}
</script>
<style lang="scss" scoped>
.nav {
  border-bottom: 1px solid #E5E7EB;
}
.nav-center {
  height: 60px;
  padding: 0 31px;
  box-sizing: border-box;
  // max-width: 1440px;
  margin: auto;
  .nav-l {
    flex: 1;
    .logo {
      width: 91px;
      height: auto;
    }
  }
  .nav-r {
    position: relative;
    z-index: 9;
    .wallet {
      margin-left: 16px;
      padding: 0 12px;
      border: 1px solid #E5E7EB;
      border-radius: 10px;
      font-size: 13px;
      line-height: 18px;
      color: #262C33;
      height: 34px;
      font-weight: 500;
      cursor: pointer;
      &.wallet-btn {
        &:hover {
          background: rgba(201, 209, 220, 0.3);
        }
      }
      .line {
        height: 12px;
        width: 1px;
        margin: 0 12px;
        background: #E5E7EB;
      }
      .avatar {
        margin-left: 12px;
      }
    }
  }
}
</style>


