import { get, post } from './axios'

//登录
export const login = (data = {}) => post('/dash/login', data)

// 获取用户自创合约列表
export const getContractList = (data = {}) => get('/dash/get_contract_list', data)

// 注册
export const regist = (data = {}) => post('/dash/regist', data)

// 获取trigger列表
export const getTriggerList = (data = {}) => get('/dash/get_trigger_list', data)

// 获取trigger 详情
export const getTriggerDetail = (data = {}) => get('/dash/get_trigger_detail', data)

// 获取目前可用过滤器列表
export const getFunctionList = (data = {}) => get('/dash/get_function_list', data)

// 获取赞助地址
export const getSponsor = (data = {}) => get('/dash/get_sponsor_list', data)

// 添加触发器
export const addTrigger = (data = {}) => post('/dash/add_trigger', data)

// 添加合约
export const addContract = (data = {}) => post('/dash/add_contract', data)

// 添加过滤器
export const addFilter = (data = {}) => post('/dash/add_filter', data)

// 添加结果
export const addResult = (data = {}) => post('/dash/add_result', data)

// 添加付款钱包
export const addSponsor = (data = {}) => post('/dash/add_sponsor', data)

// 发布触发器
export const publishTrigger = (data = {}) => post('/dash/publish_trigger', data)
