<template>
  <div class="model-w flex-center-center" v-if="isShowModel">
    <div class="model">
      <div class="model-hd flex-center-sb">
        <div class="model-title">{{title}}</div>
        <div class="model-close">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancel">
            <path d="M3.33325 3.33334L12.6666 12.6667M12.6666 3.33334L3.33325 12.6667" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="model-main">
        <div class="label-item">
          <div class="label">钱包密钥</div>
          <div class="filter">
            <div class="input">
              <textarea placeholder="请输入钱包密钥" v-model="addSponsorData.pk"></textarea>
            </div>
          </div>
        </div>
        <div class="label-item">
          <div class="label">网络</div>
          <div class="input" >
            <input type="text" v-model="chain" disabled>
          </div>
        </div>
      </div>
      <div class="ft flex-center">
        <div class="ft-btn flex-center-center" @click="cancel">取消</div>
        <div class="ft-btn ft-btn-ok flex-center-center" @click="addSponsorFun">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue'
import { addSponsor } from '../http/api'
import '@/assets/css/model.scss'
import { useMessage } from "naive-ui"

export default {
  props: ['chainId'],
  setup(props, {emit}) {
    const message = useMessage()
    const isShowModel = ref(false)
    const addSponsorData = ref({})
    const title = ref('导入钱包地址')
    const addSponsorFun = async () => {
      addSponsor({
        pk: addSponsorData.value.pk,
        chain_id: props.chainId
      }).then(res => {
        console.log(res)
        if (res.code == 0) {
          emit('add')
          addSponsorData.value = {}
        } else {
          message.error(res.msg)
        }
      })
    }
    const frequencyChange = (e) => {
      console.log(e)
    }
    const cancel = () => {
      isShowModel.value = false
      addSponsorData.value = {}
    }
    onBeforeMount(() => {
    })
    return {
      title,
      chain: props.chainId,
      isShowModel,
      addSponsorData,
      addSponsorFun,
      frequencyChange,
      cancel
    }
  },
}
</script>
<style lang="scss" scoped>
.label-item {
  position: relative;
  input:disabled {
    border-radius: 6px;
    background: #F2F3F4 !important;
    color: #B6BBC2 !important;
  }
}
</style>