<template>
  <div class="model">
    <div class="model-hd flex-center-sb">
      <div class="model-title">补贴交易</div>
      <div class="model-close">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="cancel">
          <path d="M3.33325 3.33334L12.6666 12.6667M12.6666 3.33334L3.33325 12.6667" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="model-main">
      <div class="label-item">
        <div class="label">Gas来源</div>
        <Select 
          style="width: 100%"
          placeholder="请选择付款地址"
          :value="sponsorId"
          :options="sponsorList"
          optionLabel="address"
          optionValue="id"
          @change="addressChange" />
        
        <p class="hint" @click="addSponsorFun">导入钱包地址</p>
      </div>
      <div class="label-item">
        <div class="label">同一个地址补贴的最大次数</div>
        <Select 
          style="width: 100%"
          placeholder="请选择最大补贴次数"
          :value="frequency"
          :options="frequencyList"
          optionLabel="label"
          optionValue="value"
          @change="frequencyChange" />
      </div>
    </div>
    <div class="ft flex-center">
      <div class="ft-btn flex-center-center" @click="cancel">取消</div>
      <div v-if="!resultId" class="ft-btn ft-btn-ok flex-center-center" @click="addResultFun">保存</div>
    </div>
    <AddAddressModel ref="addAddressModel" :chainId="chain" @add="add" />
  </div>
  
</template>
<script>
import { onBeforeMount, ref } from 'vue'
import { addResult, getSponsor, addSponsor } from '../http/api'
import Select from '../components/Select.vue'
import AddAddressModel from '../components/AddAddressModel'
import '@/assets/css/model.scss'
import { useMessage } from "naive-ui"
import { ethers } from 'ethers'
export default {
  props: ['chainId', 'triggerId'],
  components: {
    Select,
    AddAddressModel
  },
  setup(props, {emit}) {
    const message = useMessage()
    const sponsorId = ref('')
    const sponsorList = ref([])
    const address = ref('')
    const frequency = ref('1')
    const resultId = ref('')
    const addAddressModel = ref(null)
    const frequencyList = ref([{
      label: '1次',
      value: '1'
    }, {
      label: '2次',
      value: '2'
    }, {
      label: '3次',
      value: '3'
    }, {
      label: '4次',
      value: '4'
    }, {
      label: '5次',
      value: '5'
    }, {
      label: '6次',
      value: '6'
    }])
    const addResultFun = async () => {
      if (!sponsorId.value) {
        message.error('请选择付款地址')
        return
      }
      if (!frequency.value) {
        message.error('请选择最大补贴次数')
        return
      }
      addResult({
        trigger_id: props.triggerId,
        method_type: 'sponsor',
        sponsor_id: sponsorId.value
      }).then(res => {
        console.log(res)
        if (res.code == 0) {
          emit('saveResult')
        } else {
          message.error(res.msg)
        }
      })
    }
    const cancel = () => {
      emit('cancel')
    }
    const frequencyChange = (e) => {
      console.log(e)
    }
    const addressChange = (e) => {
      console.log(e)
      if (e == 'add') {
        let lbcWallet = ethers.Wallet.createRandom()
        addSponsor({
          pk: lbcWallet.privateKey,
          chain_id: props.chainId
        }).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.getSponsorFun(lbcWallet.address)
          } else {
            message.error(res.msg)
          }
        })
      } else {
        sponsorId.value = JSON.parse(JSON.stringify(e))
      }
    }
    const addSponsorFun = () => {
      addAddressModel.value.isShowModel = true
      addAddressModel.value.title = '导入钱包地址'
    }
    const getSponsorFun = (addr) => {
      getSponsor({chain_id: props.chainId}).then(res => {
        console.log(address.value)
        if (res.code == 0) {
          let list = res.sponsor_list
          list.push({address: '新增钱包', id: 'add'})
          sponsorList.value = JSON.parse(JSON.stringify(list))
          if (addr) {
            sponsorId.value = sponsorList.value.filter(item => item.address.toLocaleLowerCase() == addr.toLocaleLowerCase())[0].id
          }
          if (address.value) {
            sponsorId.value = sponsorList.value.filter(item => item.address.toLocaleLowerCase() == address.value.toLocaleLowerCase())[0].id
          }
        } else {
          message.error(res.msg)
        }
      })
    }
    const add = () => {
      addAddressModel.value.isShowModel = false
      getSponsorFun()
    }
    onBeforeMount(() => {
      getSponsorFun()
    })
    return {
      resultId,
      sponsorId,
      address,
      frequency,
      chain: props.chainId,
      sponsorList,
      frequencyList,
      addAddressModel,
      addResultFun,
      frequencyChange,
      addSponsorFun,
      add,
      cancel,
      addressChange
    }
  },
}
</script>
<style lang="scss" scoped>
.label-item {
  position: relative;
  .hint {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    color: #2152EC;
  }
}
</style>