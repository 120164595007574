<template>
  <div class="select">
    <div class="input">
      <label>
        <input type="text" :placeholder="placeholderData" v-model="labelData" @blur="hiddenOptions" @focus="inputFocus" @input="bindInput">
        <div class="input-num flex-center-center">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 5.33337L8.66669 11.3334L2.66669 5.33337" stroke="#B6BBC2" stroke-width="1.2" stroke-linejoin="round"/>
          </svg>
        </div>
      </label>
      
      <div class="options-w" :style="{'max-height': isShowOptions ? '300px' : '0'}" @click.stop>
        <div class="options">
          <div :class="['options-item', 'flex-center-sb', valueData == item[optionValueData] ? 'options-item-activate' : '']" v-for="(item, index) in optionsData" :key="index" @click="selectItem(item)">
            <span>{{item[optionLabelData]}}</span>
            <svg v-if="valueData == item[optionValueData]" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.56909 7.25122L6.66647 11.3486L13.0987 4.91637" stroke="#2152EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div v-if="isAddContractData" :class="['options-item', 'flex-center-center']" @click="addContract">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.0001 2V14.0001M14.0001 7.99986H2" stroke="#262C33" stroke-width="1.2" stroke-linejoin="round"/>
            </svg>
            <span style="margin-left: 8px">添加合约</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue'
export default {
  props: ['options', 'placeholder', 'value', 'optionLabel', 'optionValue', 'isAddContract'],
  setup(props, {emit}) {
    let label = ''
    props.options.forEach(e => {
      if (e[props.optionValue] == props.value) {
        label = e[props.optionLabel]
      }
    })
    const optionsData = ref(props.options)
    const placeholderData = ref(props.placeholder)
    const valueData = ref(props.value)
    const labelData = ref(label)
    const optionLabelData = ref(props.optionLabel)
    const optionValueData = ref(props.optionValue)
    const isShowOptions = ref(false)
    const inputFocus = () => {
      isShowOptions.value = true
    }
    const hiddenOptions = () => {
      isShowOptions.value = false
      setTimeout(() => {
        let options = props.options
        let option = options.filter(e => e[optionValueData.value] == valueData.value)
        if (option && option.length) option = option[0]
        else return
        if (!(option[optionLabelData.value] == labelData.value)) {
          props.options.forEach(e => {
            if (e[props.optionValue] == valueData.value) {
              labelData.value = e[props.optionLabel]
            }
          })
          optionsData.value = props.options
          label = ''
        }
      }, 150)
    }
    const bindInput = () => {
      let label = labelData.value
      let options = optionsData.value
      if (label) {
        options = options.filter(e => e[optionLabelData.value]?.toLocaleLowerCase().indexOf(label.toLocaleLowerCase()) > -1)
        optionsData.value = options
      } else {
        optionsData.value = props.options
      }
    }
    const selectItem = (item) => {
      console.log(item, optionsData.value)
      let value = item[optionValueData.value]
      let label = item[optionLabelData.value]
      if (value != 'add') {
        // optionsData.value = props.options
        labelData.value = label
        valueData.value = value
      }
      emit('change', value)
    }
    const addContract = () => {
      emit('addContract')
    }
    watch(() => props.value, (val) => {
      console.log(val, props.options)
      props.options.forEach(e => {
        if (e[props.optionValue] == props.value) {
          valueData.value = e[props.optionValue]
          labelData.value = e[props.optionLabel]
        }
      })
    }, {immediate: true})
    watch(() => props.options, (val) => {
      console.log(val, props.value)
      optionsData.value = val
      props.options.forEach(e => {
        if (e[props.optionValue] == props.value) {
          labelData.value = e[props.optionLabel]
          valueData.value = e[props.optionValue]
        }
      })
    }, {deep: true, immediate: true})
    return {
      isAddContractData: ref(props.isAddContract || false),
      isShowOptions,
      optionsData,
      placeholderData,
      valueData,
      optionLabelData,
      optionValueData,
      labelData,
      inputFocus,
      hiddenOptions,
      bindInput,
      selectItem,
      addContract
    }
  }
}
</script>
<style lang="scss" scoped>
.select {
  // .input {
  //   width: 100%;
  //   height: 40px;
  // }
  font-size: 12px;
  .input {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    font-size: 0;
    input {
      background: none;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      font-size: 12px;
    }
    .input-num {
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      font-size: 13px;
      line-height: 16px;
      color: #BABCC4;
    }
  }
  .options-w {
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 9;
    transition: all .3s;
  }
  .options {
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEFF0;
    box-shadow: 0px 4px 10px rgba(6, 30, 85, 0.08);
    border-radius: 6px;
    max-height: 200px;
    min-height: 40px;
    overflow-y: auto;
    padding: 4px;
    box-sizing: border-box;
    .options-item {
      height: 40px;
      border-radius: 4px;
      font-size: 13px;
      line-height: 16px;
      color: #262C33;
      padding: 0 12px;
      box-sizing: border-box;
      cursor: pointer;
      &.options-item-activate {
        background: rgba(33, 82, 236, 0.1);
        border-radius: 4px;
        color: #2152EC;
        font-weight: 500;
      }
      &:hover {
        background: rgba(201, 209, 220, 0.3);
      }
    }
  }
}
</style>